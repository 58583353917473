@tailwind base;
@tailwind components;
@tailwind utilities;

.cropper-avatar-view-box,
.cropper-avatar-face {
  border-radius: 50% !important;
}

p {
  margin-bottom: 0px !important;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  @apply p-0 !important;
}

.ant-picker-content th {
  @apply border border-primary-300 bg-primary-200;
}

.ant-picker-content td {
  @apply border border-primary-300 bg-primary-50;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  @apply bg-primary-600 rounded-full !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  @apply border-primary-600 rounded-full !important;
}
